export function showReferences() {

    const ifMoreReferences = $('.bottom-note__link-more-info'); 

    if (!ifMoreReferences) return;
	
	$(ifMoreReferences).on("click", function (e) {
        e.preventDefault();

        var $this = $(this);

        $this.toggleClass('show-more');
        $this.parent().find('.bottom-note__toggle').slideToggle();

        return false;
    });
} 