export function header() {

    const ifHeader = $('.header'); 

    if (!ifHeader) return;
	
	$(window).scroll(function() {
		if ($(window).scrollTop() > $('.header').height()) {
       		$('.header').addClass('header--minified');
    	} else {
    		$('.header').removeClass('header--minified');
    	}
	});

	
		
} 

	




