//import {revealMenu, closeMenu} from "../animations";

export function menu() {
    
    const ifMenu = $('.header .hamburger');

    if (!ifMenu) return;

	var $buttonMenu = $('.header .hamburger');
    var $navbar     = $('.navbar');

	$buttonMenu.on('click',function (e) {
		$(this).toggleClass('is-active');
        $('body').toggleClass('overflow');
        $navbar.toggleClass("open");
    } );


   $('.navbar__close').on('click',function (e) {
        e.preventDefault();
        $buttonMenu.click();
    });

   if ($('body').hasClass('tablet')) {
        $('.menu__item--submenu').one('click', function(e) {
            e.preventDefault();
        });
   }
}


