export function initFerrerTracking(){
	$(".ferrer-track-it").not('form').off('click.tracking').on('click.tracking', function(e) {
		var $el = $(this);
		setEvent($el.data('event'));
	});

	var filetypes = /\.(zip|exe|dmg|pdf|doc.*|xls.*|ppt.*|docx.*|xlsx.*|pptx.*|mp3|txt|rar|wma|mov|avi|wmv|flv|wav)$/i;
	var publicDownload = /(public-download)/i;
	var baseHref = '';
	if ($('base').attr('href') != undefined) {
		baseHref = $('base').attr('href');
	}

	$('a').not('[data-fancybox]').on('click', function (event) {
			var el = $(this);
			var track = true;
			var href = (typeof (el.attr('href')) != 'undefined') ? el.attr('href') : "";
			var isThisDomain = href.match(document.domain.split('.').reverse()[1] + '.' + document.domain.split('.').reverse()[0]);
			if (!href.match(/^javascript:/i)) {
				var elEv = [];
				elEv.value = 0, elEv.non_i = false;
				 if (href.match(filetypes) || href.match(publicDownload)) {
				 	if(el.data('trackingtitletag')){
						setEvent("ViewPage", "Material", href, el.data('trackingtitletag'));
				 	}else{
						setEvent("ViewPage", "Material", href);
				 	}
					elEv.loc = href;
				}
				else if (href.match(/^https?\:/i) && !isThisDomain) {
					setEvent("ViewPage", "LinkExterno", href);
					elEv.loc = href;
				}
				else
					track = false;

				if (track) {
					if (el.attr('target') == undefined || el.attr('target').toLowerCase() != '_blank') {
						setTimeout(function () {
							location.href = elEv.loc;
						}, 400);
						return false;
					}
				}
			}
		});
}

export function ferrerTrackNavigation(){
	var titleTag = undefined;
	if ($('#titleTag').length) {
		titleTag = $('#titleTag').val();
	}
	setEvent('ViewPage',undefined,location.href,titleTag);
}