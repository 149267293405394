import gsap from "gsap";

import {imageCover,imageParallax, menu, slide, animation, select, header, listAccordeon, showDisclaimer, showReferences, scrollTo,initFerrerTracking, ferrerTrackNavigation, lightbox, landingSecureGoogleTrackNavigation, cardioFlix} from "./components";

gsap.config({
    nullTargetWarn: false
});

window.App = {};

App.config = {
  ajax: {
    enabled: true,
  },
  cursorFollower: {
    enabled: true,
    disableBreakpoint: '992', // cursor will be disabled on this device width
  },
}

App.html = document.querySelector('html');
App.body = document.querySelector('body');

window.onload = function () {

  if (App.config.ajax.enabled) {
    // inicializa llamadas asíncronas para enlaces y formularios
    $(".ajax").async();
    // igual que $.async(), pero sólo para cargar HTML en un elemento del DOM. El enlace necesita un data-target que indique dónde cargar lo que venga por ajax
    $(".ajax-load").asyncLoad();
		//initBarba();
    
	}

  if (App.config.cursorFollower.enabled) {
    //Cursor.init();
  }

  document.fonts.ready.then(function () {
   	  initComponents();
  });

}

function initComponents() {
  imageCover();
  imageParallax();
  menu();
  slide();
  // animation();
  select();
  header();
  listAccordeon();
  showDisclaimer();
  showReferences();
  scrollTo();
  initFerrerTracking();
  ferrerTrackNavigation();
  lightbox();
  cardioFlix();
  landingSecureGoogleTrackNavigation();
}


















