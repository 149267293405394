
export function landingSecureGoogleTrackNavigation(){
	var currentPathname = window.location.pathname;

	$(".menu__link, .submenu__item a").on('click', function(event){
			if ($(this).attr("data-src")=='undefined' || $(this).attr("data-src")==false) {
				event.preventDefault();
			}
			var currentHostName = window.location.hostname;
			var pathNameParts = currentPathname.split("/");
			var flux = '/'; var section = 'Home';
			pathNameParts.shift();
			// Line below just for development testing purposes
			if (currentHostName.includes("192.168") || currentHostName.includes("localhost")) { pathNameParts.shift(); }
			// Line above just for development testing purposes
			if (pathNameParts.length == 1 && pathNameParts[0] != "") {
				flux = pathNameParts[0];
				section = flux;
			} else if (pathNameParts.length >= 2) {
				flux = pathNameParts[1];
				section = pathNameParts[2];
			}
			gtmt('interaction', {
				'page_name': currentPathname,
				'flujo': flux,
				'seccion': section,
				'categoria':'menu superior',
				'accion':'clic',
				'copy': $(this).attr("title"),
				'profesional':'si',
				'activo': currentPathname
			});
			if ($(this).attr("data-src")=='undefined' || $(this).attr("data-src")==false) {
				window.location.href = $(this).attr("href");
			}
	});

	if (currentPathname.endsWith('landing/secure')) {
		gtmt('pageView', {
			'page_name':'/inicio',
			'title':'Trinomia | Inicio',
			'flujo':'inicio',
			'seccion':'inicio',
			'profesional':'si',
			'activo': currentPathname
		});

		/*$(".menu__link").on('click', function(event){
			event.preventDefault();
			gtmt('interaction', {
				'page_name':'/inicio',
				'title':'Trinomia | Inicio',
				'flujo':'inicio',
				'seccion':'inicio',
				'categoria':'menu superior',
				'accion':'clic',
				'copy': $(this).attr("title"),
				'profesional':'si',
				'activo': currentPathname
			});
			window.location.href = $(this).attr("href");
		});*/

		$("a#landingSecureFormDownload").on('click', function(event){
			gtmt('interaction', {
		          'page_name': '/inicio',
		          'flujo': 'inicio',
		          'seccion': 'inicio',
		          'categoria': 'banner principal',
		          'accion': 'clic',
		          'copy': 'acceder',
		          'profesional': 'si',
		          'activo': "landing/secure"
		    });
		    gtmt('v_pageview', {
				'page_name':'/registro_email',
				'title':'Trinomia | Registro Email',
				'flujo':'registro',
				'seccion':'email',
				'profesional':'si',
				'activo': "landing/secure",
			});
		});

		$("a#downloadUrl").on('click', function(event){
			event.preventDefault();
			gtmt('interaction', {
				'page_name':'/registro_completo',
				'flujo':'registro',
				'seccion':'completo',
				'categoria':'registro',
				'accion':'clic',
				'copy':'acceder',
				'profesional':'si',
				'activo':'landing/secure',
			});
			window.location.href = $(this).attr("href");
		});
	} 
		
}