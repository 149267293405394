export function cardioFlix() {

    const ifCardioflixVideo = $('.chapter-detail__player'); 

    if (!ifCardioflixVideo) return;
	ifCardioflixVideo.click(function(e) {
        e.preventDefault();
        $('.chapter-detail__video__cover').fadeOut();
        player.play();
    });
}