import {jarallax} from 'jarallax';

export function imageCover() {

    if ( ! Modernizr.objectfit ) {
        $('.image-cover').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');

            if (imgUrl) {
                $container
                    .css('backgroundImage', 'url(' + imgUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
    }
}


export function imageParallax() {
  if (!document.querySelector('.jarallax')) {
    return;
  }
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.9
  });

}


