export function showDisclaimer() {

    const ifshowDisclaimer = $('.showDisclaimerProfessional'); 
    if (!ifshowDisclaimer) return;

    var currentPathname = window.location.pathname;

    if (!readCookie("isProfessional")) {
    	$.fancybox.open( $('.showDisclaimerProfessional'), {
			afterShow: function() {
				gtmt('v_pageview', {
					'page_name':'/aviso_contenido',
					'title':'Trinomia | Aviso de contenido',
					'flujo':'inicio',
					'seccion':'aviso de contenido',
					'profesional':'n/a',
					'activo': currentPathname,
				});

				this.$content.find('.fancybox-close-small').remove();

				this.$content.find('#not-professional').click(function(event) {
						event.preventDefault();
						gtmt('interaction', {
							'page_name':'/aviso_contenido',
							'flujo':'inicio',
							'seccion':'aviso de contenido',
							'categoria':'aviso de contenido',
							'accion':'clic',
							'copy':'no soy profesional sanitario',
							'profesional':'no',
							'activo': currentPathname
						});
						window.location.href = $(this).attr("href");
				});

				this.$content.find('.btn-close').click(function(e) {
					e.preventDefault();

					if ($(this).hasClass('professional')) {
						createCookie('isProfessional', 1, 31);
						console.log("professional");
						gtmt('interaction', {
							'page_name':'/aviso_contenido',
							'flujo':'inicio',
							'seccion':'aviso de contenido',
							'categoria':'aviso de contenido',
							'accion':'clic',
							'copy':'soy profesional sanitario',
							'profesional':'si',
							'activo': currentPathname
						});
					}

					if ($(this).hasClass('not-professional')) {
						createCookie('isProfessional', 0, 31);
						console.log("NO professional");
					}
					$.fancybox.close();
					return false;
				});
			}
	     	,clickSlide: false
			,clickOutside: false
			,clickContent: false
	        ,touch : false
	  	});
    }	
} 

export function createCookie(name, value, days) {
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		var expires = "; expires=" + date.toGMTString();
	}
	else var expires = "";
	document.cookie = name + "=" + value + expires + "; path=/";
}

export function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}