export function slide() {
	
	const slider = $('.slide');

	if (!slider) return;

  	if($(".slide .swiper-slide").length == 1) {
	    $('.slide .swiper-navigation').hide();
	    $('.slide .swiper-pagination').hide();
	}

	initSlider($('.slide'));
}

export function initSlider($elements) {
	$elements.each(function(index) {
		$(this).addClass('s'+index);

		var $this 	= $(this),
			type = $this.data('type') || 'default',
			options = {
				"default" :  {
					loop:true,
					//autoHeight:true,
					autoplay: {
				    	delay: 5000,
				  	},
					speed:900,
		            effect: 'slide',
			      	pagination: {
				        el: '.slide.s'+index +' .swiper-pagination',
				        //dynamicBullets: true,
				        clickable: true,
				    },
				    navigation: {
				        nextEl: '.slide.s'+index +' .swiper-button-next',
				        prevEl: '.slide.s'+index +' .swiper-button-prev',
				    }
				},
				"loader" : {
					loop:true,
					autoplay: {
				    	delay: 5000,
				    	disableOnInteraction: false
				  	},
					effect: 'fade',
					fadeEffect: {
					    crossFade: true
					},
			      	pagination: {
				        el: '.slide.s'+index +' .swiper-pagination',
				        clickable: true
				    },
				    on: {
					    beforeInit: function () {
					    	$('.swiper-slide:not(.swiper-slide-duplicate)').each(function(index) {
							    if ($(this).find('.main-slide__content').hasClass('main-slide__content--orange')) {
							    	$('.main-slide .swiper-pagination .swiper-pagination-bullet:eq('+index+')').addClass('orange');
							    }

							    if ($(this).find('.main-slide__content').hasClass('main-slide__content--blue')) {
							    	$('.main-slide .swiper-pagination .swiper-pagination-bullet:eq('+index+')').addClass('blue');
							    }
							});
					    },
					    slideChange: function () {
						    $('.swiper-slide:not(.swiper-slide-duplicate)').each(function(index) {
							    if ($(this).find('.main-slide__content').hasClass('main-slide__content--orange')) {
							    	$('.main-slide .swiper-pagination .swiper-pagination-bullet:eq('+index+')').addClass('orange');
							    }

							    if ($(this).find('.main-slide__content').hasClass('main-slide__content--blue')) {
							    	$('.main-slide .swiper-pagination .swiper-pagination-bullet:eq('+index+')').addClass('blue');
							    }
							});
					    }
					}
				},
				"some-items-carousel": {
					autoplay: false,
					loop: false,
					slidesPerView: 'auto',
					centeredSlides: true,
					spaceBetween: 0,
					centeredSlides: false
				}
			};

		var opts = $.extend(options.default, options[type]);
		if ($(this).is(":hidden")) {
			$(this).css({'display':'block'});
			var slide = new Swiper($('.slide.s'+index+' .swiper-container'), opts);
			$(this).css({'display':''});
		} else {
			var slide = new Swiper($('.slide.s'+index+' .swiper-container'), opts)
		}
	});
}
