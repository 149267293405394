import {initComponentsLightbox} from './init-components-lightbox';

export function lightbox(){
	// If fancybox doesn't exist, there is nothing else here to do
	if(typeof $.fn.fancybox !== 'function') return;
	initLightbox($('a.lightbox'));

	$('.new-lightbox').click(function(e) {
		e.preventDefault();

		if(typeof $(this).data('trackingaction') && typeof CONFIG.get('gtag_code') !== 'undefined' && CONFIG.get('gtag_code').length) {
			gtag('event',  $(this).data('trackingaction'), {
			  'value': 1
			});
		}

		$.fancybox.open({
			src  : $('.new-lightbox').data('src'),
			type : 'ajax',
			opts : {
				afterShow : function( instance, current ) {
					initComponentsLightbox();
				}
			}
		});
	})
}


export function initLightbox($elements){
	$elements.each(function() {
		var $link = $(this);

		$link.off('click.lightbox').on('click.lightbox', function(e) {
			e.preventDefault();

			var  $link = $(this)
				,href = $link.attr('href') + (($link.attr('href').indexOf('?') === -1) ? '?' : '&') + $.param($link.data())
				,type = $link.data('type') || 'default';

			var options = {
				'default' :  {
					 type: 'ajax'
					,src: href
					,closeExisting: true
					,keyboard: false
					,infobar: false
					,baseClass: 'pink-close'
					,animationEffect  : 'fade'
					,animationDuration  : 150
					,closeEffect : 'fade'
					,nextEffect: 'fade'
					,prevEffect: 'fade'
					,padding:0
					,autoSize : false
					,maxWidth:700
					,height:'auto'
					,width: '95%'
					,margin: [0, 0, 0, 0]

					,ajax: {
						settings: {
							data: {
								fancybox: false
							}
						}
					}
					,media : {
						youtube : {
					    	params : {
						        modestbranding: 1,
						        showinfo: 0
					    	}
					 	}
					}
					,afterShow: function() {
						initComponentsLightbox();

						this.$content.find('.close, .lightbox-close').on('click', function(e) {
							e.preventDefault();
							$.fancybox.close();
							return false;
						});
					}
					,beforeShow: function() {

					}
				},
				'cms' : {
					 autoSize: false
					,margin: 0
					,height: 'auto'
					,width: 'auto'
                    ,touch : false
					,baseClass: 'cms-form'
					,clickSlide: false
					,clickOutside: false
					,clickContent: false
				},
				'image' : {
					 type: 'image'
					,height: 'auto'
					,width: 'auto'
				},
				'no-close' : {
					afterShow: function() {
						this.$content.find('.fancybox-close-small').remove();

						this.$content.find('.btn-close').click(function(e) {
							e.preventDefault();

							if ($(this).hasClass('booking-cookie')) {
								var date = new Date(); date.setDate(date.getDate()+15);
								Cookies.set('carandu_how_to_book_viewed', 1, {expires: new Date(date)});
							}
							$.fancybox.close();
							return false;
						});

                    	if ($('.slide').length) {
                    		initSlider($('.slide'));
                    	}
					}
					,clickSlide: false
					,clickOutside: false
					,clickContent: false
                    ,touch : false
				}
			};
			var opts = $.extend({}, options.default, options[type]);
			$.fancybox.open( opts );
		});
	});
}
