export function select() {
    
    const ifSelect = $('.select2');

    if (!ifSelect) return;

    $(".select2").each(function() {
        var  $this = $(this);
        initSelect2($this);
    });
}

export function initSelect2($this, opt) {
    var defaultOpt = {
        width: '100%',
        disabled: $this.attr('readonly') == 'readonly',
        placeholder: $this.data('placeholder')
    };

    opt = $.extend({}, defaultOpt, opt);
    if($this.hasClass("select2-hidden-accessible")) $this.select2('destroy');
    $this.select2(opt);
};

   