
export function listAccordeon() {

    const ifListAccordeon = $('.list-accordeon'); 
    if (!ifListAccordeon) return;
	
	if ($(".list-accordeon__block").length) {
		$(".list-accordeon__block").each(function () {

	        $(this).find('a.list-accordeon__title').on("click", function (e) {
	            e.preventDefault();

	            var $this = $(this);

	            $this.toggleClass('list-accordeon__title--active');
	            $this.parent().find('.list-accordeon__content').slideToggle();

	            return false;
	        });
	    });
	}
} 

	




