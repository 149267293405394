//import {revealMenu, closeMenu} from "../animations";

export function scrollTo() {
    
    const ifGoTo = $('.go-to-anchor');
    
    if (!ifGoTo) return;

    ifGoTo.on('click', function(event) {
    	event.preventDefault();
		goToScroll(this);

		if ($(this).hasClass('faq')) {
			$(this.hash).find('a.list-accordeon__title').click();
		}
	});
}


export function goToScroll(link) {
	console.log("entro 3");
    if (link.hash !== "") {
		event.preventDefault();

		// Store hash
		var hash = link.hash;
		gentlyScrollTo($(hash), hash);
	}
}

export function gentlyScrollTo($el, hash) {
	console.log("entro 4");
	if($el.length) {
		$('.header').removeClass('header--minified pinned unpinned');
		$('html, body').animate({
			scrollTop: $el.offset().top - $('.header').height()
		}, 800, function(){
			//window.location.hash = hash;
		});
	}
}