export function animation() {    
    const ifAnimation = $('.animation');

    if (!ifAnimation) return;

    $('.animation').css({
		visibility: 'hidden'
	}).each(function(){
		var $this = $(this);
		var animation_waypoint = new Waypoint({
			element: $this.get(0),
			handler: function (direction) {
				$this
					.css({ visibility: 'visible' })
					.addClass('animated');
			},
			offset: '90%'
		});
	});
}
